import React, { Component, useContext } from 'react';
import { Container } from 'react-bootstrap';

function About() {
    return (
        <Container className="text-left">
            <h2>Big-brain education</h2>
            <p>
                High school and college students
                deserve more engaging tools for
                learning. Bigbraingames aims to
                provide this by aggregating
                educational web games.
            </p>
            <h2>Big-brain creators</h2>
            <p>
                Students are bombarded by ads enough.
                Instead, this platform is supporting
                game creators to self-promote through
                Patreon pages.
            </p>
            <h2>Big-brave new world</h2>
            <p>
                It doesn't stop here, we believe
                innovation is driven by disruptive
                technology. Whether it's holograms
                or personalized Ai systems, Bigbrain Inc.
                wants to lead a brighter and exciting
                future for education and information tech.
            </p>
            <a href="mailto:contact@bigbraingames.io">
                contact@bigbraingames.io
            </a>
        </Container>
    );
}

export default About;