import React, { Component, useContext } from 'react';
import SubmitForm from './SubmitForm';
import UserContext from './UserContext';

function Submit() {
    const user = useContext(UserContext);
    if (user) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <SubmitForm user={user}/>
            </div>
        );
    } else {
        return (
            <h3 style={{ display: 'flex', justifyContent: 'center' }}>
                Log-in / Sign-up to submit
            </h3>
        );
    }
}

export default Submit;