import React, { Component } from 'react';
import { Container, Row, Col, CardDeck } from 'react-bootstrap';
import { API, Storage } from 'aws-amplify';
import { listGames } from '../graphql/queries';
import GameLink from './GameLinkComponent';

class GameList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            games: []
        };
    }

    componentDidMount() {
        this.fetchGames().then(() => {
            this.toggleLoading();
        });
    }

    toggleLoading() {
        this.setState({ loading: !this.state.loading });
    }

    async fetchGames() {
        const apiData = await API.graphql({
            query: listGames, variables: {},
            authMode: "AWS_IAM"
        });
        const gamesFromAPI = apiData.data.listGames.items;
        await Promise.all(gamesFromAPI.map(async game => {
            game.thumbnail = await Storage.get(game.build + "/thumbnail");
            return game;
        }))
        this.setState({ games: apiData.data.listGames.items });
    }

    render() {
        let gameList = () => {
            if (this.state.games.length > 0) {
                let links = this.state.games.map((game) => {
                    let id = game.id;
                    return (
                        <GameLink key={id} gameId={id}
                            gameInfo={game} />
                    );
                });
                return links;
            } else {
                return <div></div>;
            }
        }

        let spinner = () => {
            if (this.state.loading) {
                return <div>loading</div>;
            } else {
                return <div></div>;
            }
        }

        return (
            <Container>
                {spinner()}
                <CardDeck>
                    {gameList()}
                </CardDeck>
            </Container>
        );
    }
}

export default GameList;