/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGame = /* GraphQL */ `
  query GetGame($id: ID!) {
    getGame(id: $id) {
      id
      gameTitle
      description
      creatorId
      subject
      course
      patreonLink
      build
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listGames = /* GraphQL */ `
  query ListGames(
    $filter: ModelgameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGames(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        gameTitle
        description
        creatorId
        subject
        course
        patreonLink
        build
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
