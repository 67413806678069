import React, { Component } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import Amplify, { Auth, Hub } from 'aws-amplify';
import Header from './HeaderComponent';
import GameList from './GameListComponent';
import GamePage from './GamePageComponent';
import Submit from './SubmitPageComponent';
import About from './AboutPageComponent';
import UserContext from './UserContext';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null
        };

    }

    componentDidMount() {
        Hub.listen("auth", ({ payload: { event, data } }) => {
            switch (event) {
                case "signIn":
                    this.setState({ user: data });
                    break;
                case "signOut":
                    this.setState({ user: null });
                    break;
            }
        });
        Auth.currentAuthenticatedUser()
            .then(user => this.setState({ user: user }))
            .catch(() => { });
    }

    render() {

        return (
            <UserContext.Provider value={this.state.user}>
                <Header />
                <Switch>
                    <Route path='/home' component={GameList} />
                    <Route path='/submit' component={Submit} />
                    <Route path='/games/:gameId' component={GamePage} />
                    <Route path='/about' component={About} />
                    <Redirect to='/home' />
                </Switch>
            </UserContext.Provider>
        );
    }
}

export default withRouter(Main);