/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:1a5d3b6d-1170-449b-b6d6-b5b716fd33db",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_ThpNJ722U",
    "aws_user_pools_web_client_id": "cpgsecb5sfv7k3lvq5atd4l7g",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://ku5crb3fsfbgjhad2nri3fryfi.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "bigbraingamesdevf91f7644171a4555be9f24853767e870012-staging",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
