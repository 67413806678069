import React, { Component } from 'react';
import { ResponsiveEmbed } from 'react-bootstrap'
import { API, Storage } from 'aws-amplify';
import { getGame } from '../graphql/queries';

class GamePage extends Component {
    constructor(props) {
        super(props);
        this.gameID = this.props.match.params.gameId;
        this.state = {
            gameInfo: null,
            indexURL: null,
            loading: true
        };
    }

    componentDidMount() {
        this.fetchIndex(this.gameID).then(() => {
            this.toggleLoading();
        });
    }

    toggleLoading() {
        this.setState({ loading: !this.state.loading });
    }

    async fetchIndex(gameID) {
        const apiData = await API.graphql({
            query: getGame, variables: { id: gameID },
            authMode: "AWS_IAM"
        });
        let build = apiData.data.getGame.build;
        const indexURL = await Storage.get(build + "/index.html");
        this.setState({ indexURL: indexURL });
    }

    render() {
        const unityFrame = () => {
            if (this.state.indexURL) {
                return (
                    <ResponsiveEmbed>
                        <iframe src={this.state.indexURL}></iframe>
                    </ResponsiveEmbed>
                );
            } else {
                return <div></div>;
            }
        }

        return (
            <div>
                <h3>Click on the iframe to start playing</h3>
                {unityFrame()}
            </div>
        );
    }
}

export default GamePage;