import React, { Component } from 'react';
import { Nav, Navbar, Card, Button, Image } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';

class GameLink extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageURL: null,
            link: false
        };
    }

    componentDidMount() {

    }

    render() {
        if (this.state.link) {
            return <Redirect push to={"/games/" + this.props.gameId} />
        }

        return (
            <Card onClick={() => { this.setState({ link: true }) }}
                style={{ cursor: "pointer" }}>
                <Card.Img id={this.props.gameId + 'img'} alt="Thumbnail"
                    variant="top" src={this.props.gameInfo.thumbnail} />
                <Card.Body>
                    <Card.Title>{this.props.gameInfo.gameTitle}</Card.Title>
                    <Card.Text>
                        {this.props.gameInfo.description}
                    </Card.Text>
                </Card.Body>
            </Card>
        );
    }
}

export default GameLink;