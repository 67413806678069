import React, { useState } from 'react';
import { Form, Button, Alert, Spinner } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import * as yup from 'yup';
import { v4 as uuid } from 'uuid';
import { API, Storage } from 'aws-amplify';
import { createGame as createGameMutation } from '../graphql/mutations';

const subjects = require('../data/subjects.json').Subjects;
const schema = yup.object().shape({
    gameBuild: yup
        .mixed()
        .required("Required"),
    thumbnail: yup
        .mixed()
        .required("Required")
        .test('fileType', 'JPEG or PNG file', (value) => {
            if (value[0]) {
                return value && (value[0].type === 'image/jpeg' ||
                    value[0].type === 'image/png');
            }
        }),
    gameTitle: yup.string().required("Required"),
    patreonLink: yup.string().url().matches(/patreon/i,
        { message: "Not a Patreon link", excludeEmptyString: true }),
    agree: yup.boolean().oneOf([true], 'Required'),
});

export default function SubmitForm(props) {
    const { register, handleSubmit, errors, control, watch } = useForm({ resolver: yupResolver(schema) });
    const onSubmit = (data) => {
        setSubmitting(true);
        let creatorId = `${uuid()}`
        const build = `${uuid()}`
        let queryData = {
            gameTitle: data.gameTitle,
            description: data.description,
            creatorId: creatorId,
            subject: data.subject,
            course: data.course,
            patreonLink: data.patreonLink,
            build: build
        }
        createGame(queryData);
        uploadBuild(build, data).then(() => {
            setSubmitting(false);
            setFinished(true);
        });
    };

    const [submitting, setSubmitting] = useState(false);
    const [finished, setFinished] = useState(false);
    const [gameID, setGameID] = useState(null);

    async function uploadBuild(build, data) {
        for (let i = 0; i < data.gameBuild.length; i++) {
            let meta = {};
            let name = data.gameBuild[i].name;
            let relPath = data.gameBuild[i].webkitRelativePath;
            if (name === "index.html") {
                meta = { ...meta, contentType: "text/html" };
            } else if (name.includes("loader") ||
                name.includes(".framework")) {
                meta = { ...meta, contentType: "application/javascript" };
            } else if (name.includes(".wasm")) {
                meta = { ...meta, contentType: "application/wasm" };
            }
            relPath = relPath.substring(relPath.indexOf("/"));
            await Storage.put(build + relPath, data.gameBuild[i], meta);
        }
        await Storage.put(build + "/thumbnail", data.thumbnail[0]);
    }

    async function createGame(data) {
        const apiData = await API.graphql({ query: createGameMutation, variables: { input: data } });
        setGameID(apiData.data.createGame.id);
    }

    const watchSubject = watch("subject", "Mathematics");

    let subjectOptions = () => {
        let elements = Object.keys(subjects).map((subject) => {
            return <option key={subject} value={subject}>{subject}</option>
        })
        return elements;
    }
    let courseOptions = () => {
        let courses = subjects[watchSubject].Courses;
        let elements = courses.map((course) => {
            return <option key={course}
                value={course}>{course}</option>
        })
        return elements;
    }

    let required = <span style={{ color: "red" }}>* </span>

    let pageContent = () => {
        if (submitting) {
            return (
                <div>
                    <h3>Submitting...</h3>
                    <Spinner animation="border" variant="primary">
                    </Spinner>
                </div>
            );
        } else if (finished) {
            return (
                <div>
                    <h3>Finished!</h3>
                    <h3>View <a href={"games/" + gameID}>here</a></h3>
                </div>
            )
        } else {
            return (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Row>
                        <Form.Label>{required}Game Build:</Form.Label>
                    </Form.Row>
                    <Form.Row>
                        <ErrorMessage name="gameBuild" errors={errors}></ErrorMessage>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group>
                            <input ref={register} webkitdirectory="" directory=""
                                multiple control={control} type="file" name="gameBuild"></input>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label>{required}Thumbnail:</Form.Label>
                    </Form.Row>
                    <Form.Row>
                        <ErrorMessage name="thumbnail" errors={errors}></ErrorMessage>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group>
                            <input ref={register}
                                control={control} type="file" name="thumbnail"></input>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label>{required}Subject:</Form.Label>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group>
                            <Form.Row>
                                <ErrorMessage name="subject" errors={errors}></ErrorMessage>
                            </Form.Row>
                            <Controller as={<Form.Control as="select"
                            />}
                                rules={register}
                                control={control}
                                name="subject" defaultValue="Mathematics">
                                {subjectOptions()}
                            </Controller>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label>{required}Course:</Form.Label>
                    </Form.Row>
                    {watchSubject && (
                        <Form.Row>
                            <Form.Group>
                                <Form.Row>
                                    <ErrorMessage name="course" errors={errors}></ErrorMessage>
                                </Form.Row>
                                <Controller as={<Form.Control as="select"
                                />} control={control}
                                    rules={register}
                                    name="course" defaultValue={subjects[watchSubject].Courses[0]}>
                                    {courseOptions()}
                                </Controller>
                            </Form.Group>
                        </Form.Row>
                    )}
                    <Form.Row>
                        <Form.Label>{required}Game title:</Form.Label>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group>
                            <Form.Row>
                                <ErrorMessage name="gameTitle" errors={errors}></ErrorMessage>
                            </Form.Row>
                            <Controller as={<Form.Control />}
                                rules={register}
                                control={control} type="text"
                                placeholder="Game title" name="gameTitle" defaultValue="" />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group>
                            <Controller as={<Form.Control />}
                                rules={register}
                                control={control} type="text"
                                placeholder="Description" name="description" defaultValue="" />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group>
                            <Form.Row>
                                <ErrorMessage name="patreonLink" errors={errors}></ErrorMessage>
                            </Form.Row>
                            <Controller as={<Form.Control />}
                                rules={register}
                                control={control} type="url"
                                placeholder="Your Patreon link" name="patreonLink" />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group>
                            <Form.Label>{required}I agree:</Form.Label>
                            <Form.Row>
                                <ErrorMessage name="agree" errors={errors}></ErrorMessage>
                            </Form.Row>
                            <input ref={register} name="agree" defaultChecked={false}
                                type="checkbox" control={control} />
                        </Form.Group>
                    </Form.Row>
                    <Button type="submit" variant="success">Submit</Button>
                </form>
            );
        }
    }
    return <div>{pageContent()}</div>;
}