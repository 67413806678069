import React, { Component } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Login from './LoginModalComponent';
import AccountButton from './AccountButton';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        };
        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
    }

    toggleModal() {
        this.setState({ showModal: !this.state.showModal });
    }

    render() {
        return (
            <div>
                <Navbar expand="md">
                    <Navbar.Brand>
                        <img
                            src="/assets/bigbrain_title.png"
                            alt="bigbraingames"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <LinkContainer to='/home'>
                                <Nav.Link>
                                    <h3>Games</h3>
                                </Nav.Link>
                            </LinkContainer>
                            <LinkContainer to='/submit'>
                                <Nav.Link>
                                    <h3>Submit</h3>
                                </Nav.Link>
                            </LinkContainer>
                            <LinkContainer to='/about'>
                                <Nav.Link>
                                    <h3>Hmm?</h3>
                                </Nav.Link>
                            </LinkContainer>
                        </Nav>
                        <Nav>
                            <AccountButton context={this.context}
                                toggleModal={this.toggleModal} />
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <Login show={this.state.showModal}
                    onHide={this.toggleModal} />
            </div >
        );
    }
}

export default Header;