import React, { Component, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import Amplify, { Auth } from 'aws-amplify';
import { AmplifyAuthenticator } from '@aws-amplify/ui-react';
import UserContext from './UserContext';

function ModalContent() {
    const user = useContext(UserContext);
    if (user) {
        return (
            <Modal.Header>Success!</Modal.Header>
        );
    } else {
        return <AmplifyAuthenticator />;
    }
}

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <Modal show={this.props.show} animation={false}
                onHide={() => this.props.onHide()}>
                <ModalContent />
            </Modal>
        );
    }
}

export default Login;