import React, { useContext } from 'react';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import UserContext from './UserContext';

function AccountButton(props) {
    const user = useContext(UserContext);
    if (user) {
        // User is signed in.
        return (
            <DropdownButton variant="light"
                title="Account" className="md-ml-auto 
                align-self-center">
                <Dropdown.Item onClick={() => {
                    Auth.signOut()
                }}>Sign out</Dropdown.Item>
            </DropdownButton >
        );
    } else {
        // No user is signed in.
        return (
            <Button className="md-ml-auto 
                align-self-center"
                onClick={props.toggleModal}>
                Log-in / Sign-up
            </Button>
        );
    }
}

export default AccountButton;